@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.navBar {
  width: 100%;
  height: 72px;
  box-shadow: 0 1px 0 0 #e2e2e2;
  background-color: #1c1c1c;
  vertical-align: middle;
  position: relative;
}

.items {
  display: flex;
  justify-content: space-between;
}

.itemsLeft {
  display: flex;
  height: 72px;
}

.itemsRight {
  display: flex;
  align-items: center;
}

.image {
  display: flex;
  a {
    display: flex;
  }
}

.menuItem {
  vertical-align: middle;
  line-height: 72px;
  margin-left: 32px;

  span {
    color: $gray-placeholder;
  }

  > a:hover > span {
    color: white;
  }

  &Selected {
    font-weight: 500;
    color: white !important;
  }
}
.isSelected {
  border-bottom: 2px solid white;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.languageSwitcher {
  margin-right: 26px;
}
